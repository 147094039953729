import { CRData, CRDataSerializer } from "./cr-data"
import { Injectable } from "@angular/core"
import { ApiResourceSerializer } from "../_core/api-resource.serializer"
import { JsonObject } from "../_core/types"

export class CRRegistration {
    public static STATUS_NEW = 10
    public static STATUS_EMAIL_CONFIRMED = 20
    public static STATUS_PHONE_CONFIRMATION = 30
    public static STATUS_ACTIVE = 40
    public static MODE_PREREGISTRATION = 10
    public static MODE_QUOTE = 20
    public static DEFAULT_TERM_LENGTH = 3

    id: number
    isFinished: boolean
    isPartialApprovalReset: boolean
    locked: boolean = false
    appRegistrationId: string
    createdAt: Date
    updatedAt: Date
    sellerId: string
    partnerId: string
    data: CRData
    callSetupComplete: boolean = false
    productsHasChanged: boolean = false

    // Quote additional data
    mode: number
    quoteSentEmails: {
        sentAt: Date
        email: string
    }[]
    isBespoke: boolean
}

@Injectable({
    providedIn: "root",
})
export class CRRegistrationRegistrationSerializer implements ApiResourceSerializer {
    constructor(private customerRegistrationDataSerializer: CRDataSerializer) {}

    fromJson(json: JsonObject): CRRegistration {
        const record = new CRRegistration()
        record.id = json.id
        record.isFinished = json.is_finished
        record.isPartialApprovalReset = json.is_partial_approval_reset
        record.isBespoke = json.is_bespoke
        record.locked = json.locked ?? false
        record.mode = json.mode
        record.createdAt = json.created_at ? new Date(json.created_at) : null
        record.updatedAt = json.updated_at ? new Date(json.updated_at) : null
        record.appRegistrationId = json.app_registration_id
        record.sellerId = json.seller_id
        record.partnerId = json.partner_id
        record.data =
            json.data !== undefined && json.data !== null
                ? this.customerRegistrationDataSerializer.fromJson(json.data)
                : null
        record.quoteSentEmails =
            json.quote_sent_emails !== null && json.quote_sent_emails !== undefined
                ? json.quote_sent_emails.map(item => {
                      return {
                          sentAt: item.sent_at ? new Date(item.sent_at) : null,
                          email: item.email,
                      }
                  })
                : []

        record.callSetupComplete = json.call_setup_complete
        return record
    }

    // TODO
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    toJson(record: CRRegistration): any {
        return {
            id: record.id,
            is_finished: record.isFinished,
            is_partial_approval_reset: record.isPartialApprovalReset,
            is_bespoke: record.isBespoke,
            locked: record.locked,
            mode: record.mode,
            app_registration_id: record.appRegistrationId,
            seller_id: record.sellerId,
            partner_id: record.partnerId,
            data:
                record.data !== undefined && record.data !== null
                    ? this.customerRegistrationDataSerializer.toJson(record.data)
                    : null,
            quote_sent_emails:
                record.quoteSentEmails !== null && record.quoteSentEmails !== undefined
                    ? record.quoteSentEmails.map(item => {
                          return {
                              sent_at: item.sentAt,
                              email: item.email,
                          }
                      })
                    : null,

            call_setup_complete: record.callSetupComplete,
        }
    }
}
